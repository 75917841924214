import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLayoutError,
  setLayoutText,
  triggerLayoutRender,
} from '../redux/actions';

const LayoutEditor = () => {
  const [timer, setTimer] = useState(null);

  const dispatch = useDispatch();
  const config = useSelector(state => state.config);
  const layout = useSelector(state => state.layout);
  const drawing = useSelector(state => state.drawing);

  const handleChange = (event) => {
    dispatch(setLayoutText(event.target.value));
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      const calcResult = layliValidate(event.target.value);

      if (calcResult !== null && calcResult !== '') {
        dispatch(setLayoutError(calcResult));
      } else {
        dispatch(setLayoutError(null));
      }
    }, 500);

    setTimer(newTimer);
  };

  useEffect(() => {
    async function fetchDefaultText() {
      try {
        const response = await fetch('/default.layli');
        if (!response.ok) {
          throw new Error('Failed to fetch default text file');
        }
        const defaultText = await response.text();
        dispatch(setLayoutText(defaultText));
      } catch (error) {
        console.error('Error fetching default text:', error);
      }
    }

    fetchDefaultText();
    setTimeout(() => {
      dispatch(triggerLayoutRender());
    }, 150);
  }, []);

  return (
    <div className='flex flex-col'>
      <input
        id="validationResult" data-cy="validation-result"
        type="text"
        className="bg-gray-200 p-2 rounded-md"
        disabled
        value={layout.error}
      />
      <label id="inputTextAreaId" htmlFor="inputTextArea">Enter your input:</label>
      <textarea
        id="inputTextArea" data-cy="input"
        value={layout.text}
        onChange={handleChange}
        rows={10} cols={40}
      ></textarea>
    </div>
  );
};

export default LayoutEditor;