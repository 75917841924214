import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setConfigPathStrategy,
    setConfigNodeLayout,
    setLayoutText,
    setDrawingError,
    triggerLayoutRender,
} from '../redux/actions';

export function findRectContainingPoint(svgElement, event) {
    // Get the mouse coordinates relative to the SVG container
    var svgRect = svgElement.getBoundingClientRect();
    var mouseX = event.clientX - svgRect.left;
    var mouseY = event.clientY - svgRect.top;

    // Get all rect elements within the SVG
    var rects = svgElement.querySelectorAll('rect');

    // Iterate through each rect element
    for (var i = 0; i < rects.length; i++) {
        var rect = rects[i];

        // Get the bounding box of the current rect
        var rectBox = rect.getBBox();

        // Check if the mouse coordinates are inside the bounding box of the rect
        if (mouseX >= rectBox.x && mouseX <= rectBox.x + rectBox.width &&
            mouseY >= rectBox.y && mouseY <= rectBox.y + rectBox.height) {
            // Return the rect element if the mouse coordinates are inside it

            return rect;
        }
    }

    // If no rect contains the mouse coordinates, return null
    return null;
}

const Diagram = () => {
    const [draggingDetails, setDraggingDetails] = useState(null);
    const drawing = useSelector(state => state.drawing);
    const dispatch = useDispatch();

    useEffect(() => {
        // Define the callback function to execute when mutations are observed
        const observerCallback = (mutationsList, observer) => {
            const svg = document.getElementById('outputArea')?.querySelector('svg');
            if (svg) {
                svg.addEventListener('mousedown', (e) => handleMouseDown(e, svg));

                // Once the SVG is found and listeners are attached, we can disconnect the observer
                observer.disconnect();
            }
        };

        // Create a MutationObserver to watch for changes in the 'outputArea' div
        const observer = new MutationObserver(observerCallback);

        const outputArea = document.getElementById('outputArea');
        if (outputArea) {
            observer.observe(outputArea, { childList: true, subtree: true });
        }

        // Attach global mouse move and mouse up listeners
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            // Clean up listeners and observers when component unmounts
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            observer.disconnect();
        };
    }, [draggingDetails]);

    const handleMouseDown = (e, svg) => {
        // ignore right clicks
        if (e.button != 0) {
            return;
        }

        let rect = findRectContainingPoint(svg, e);
        if (!rect) {
            return;
        }

        const rectId = rect.getAttribute("id");
        const origRectPos = {
            x: parseFloat(rect.getAttribute("x")),
            y: parseFloat(rect.getAttribute("y")),
        };
        const rectText = document.querySelector(`text[id="${rectId}-text"]`);
        const origRectTextPos = {
            x: parseFloat(rectText.getAttribute("x")),
            y: parseFloat(rectText.getAttribute("y")),
        };


        setDraggingDetails({
            rect: {
                id: rectId,
                x: origRectPos.x,
                y: origRectPos.y,
            },
            rectText: {
                id: rectText.getAttribute("id"),
                x: origRectTextPos.x,
                y: origRectTextPos.y,
            },
            movement: {
                x: 0,
                y: 0,
            }
        });
    };

    const handleMouseMove = (e, svg) => {
        if (!draggingDetails) {
            return;
        }

        let newMovement = {
            x: e.movementX+draggingDetails.movement.x,
            y: e.movementY+draggingDetails.movement.y,
        }

        let dataPos = {
            x: Math.round(newMovement.x / 20),
            y: Math.round(newMovement.y / 20),
        }
        
        let delta = {
            x: dataPos.x * 20,
            y: dataPos.y * 20,
        }

        let rect = document.querySelector(`rect[id="${draggingDetails.rect.id}"]`);
        let text = document.querySelector(`text[id="${draggingDetails.rectText.id}"]`);

        let newRectPos = {
            x: draggingDetails.rect.x + delta.x,
            y: draggingDetails.rect.y + delta.y,
        }

        rect.setAttribute("x", newRectPos.x);
        rect.setAttribute("y", newRectPos.y);
        rect.setAttribute("data-pos-x", Math.round(newRectPos.x / 20));
        rect.setAttribute("data-pos-y", Math.round(newRectPos.y / 20));
        text.setAttribute("x", draggingDetails.rectText.x + delta.x);
        text.setAttribute("y", draggingDetails.rectText.y + delta.y);

        console.log("Setting data posistion", dataPos.x, dataPos.y);

        setDraggingDetails({
            ...draggingDetails,
            movement: newMovement,
        });
    };

    const handleMouseUp = () => {
        if (draggingDetails) {
            const svg = document.getElementById('outputArea').innerHTML;
            if (svg) {
                let result = JSON.parse(svgToAbsolute(svg));
                console.log("Got result", result)
                if (result.error) {
                    dispatch(setDrawingError(result.error));
                } else {
                    dispatch(setConfigNodeLayout('absolute'));
                    dispatch(setConfigPathStrategy('in-order'));
                    dispatch(setLayoutText(result.result));
                    dispatch(triggerLayoutRender());
                }
            }
        }
        setDraggingDetails(null);
    };

    return <div
        id="outputArea"
        className="mt-4 p-2 rounded-md"
        dangerouslySetInnerHTML={{ __html: drawing.svg }}
    />
}

export default Diagram;
