import React from'react';
import Layli from '../Layli';

const layli = await Layli.get();

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<header>
            <img src="logo.svg" alt="Layli Logo" />
            <div>
                <h1>Layli</h1>
                <h2>Draw pretty layout diagrams as code</h2>
                <h5>Using layli <span id="releaseVersion" data-cy="version">{layli.version()}</span></h5>
            </div>
        </header>
        );
    }
};

export default Header;