// import './wasm_exec.js';

let wasmModuleInitialized = false;
export async function loadWasmModule() {
    console.log("loading WASM")
    if (wasmModuleInitialized) {
        console.log("WASM already loaded")
        return Promise.resolve();
    }


  return new Promise((resolve, reject) => {
    const go = new Go();
    console.log("initialising WASM stream");
    WebAssembly.instantiateStreaming(fetch("layli.wasm"), go.importObject)
        .then(result => {
            console.log("compiling WASM");
            go.run(result.instance);

            wasmModuleInitialized = true;

            console.log("returning WASM");
            resolve();
        })
        .catch(error => {
            console.error('Error loading WebAssembly module:', error);
            reject(error);
        });
    });
}

class Layli {
    static instance;
    static async get() {
        await loadWasmModule();
        if (this.instance == null) {
            this.instance = new Layli();
        }

        return this.instance;
    }


    constructor() {
        this.wasmModule = null;
        this.wasm = null;
    }

    version() {
        try {
            console.log("getting version", window.layliVersion)
            return window.layliVersion; // Cast to any to avoid TypeScript error
        } catch (ex) {
            console.log("Could not get version:", ex);
            return "error"
        }
    }
}

export default Layli;