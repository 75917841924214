import { configureStore } from '@reduxjs/toolkit';
import { configReducer, drawingReducer, layoutReducer } from './reducers/index';
import { resetLayoutMiddleware, resetPathStrategyMiddleware, renderLayoutMiddleware } from './middleware/index';

const store = configureStore({
  reducer: {
    config: configReducer,
    layout: layoutReducer,
    drawing: drawingReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(
        resetLayoutMiddleware,
        resetPathStrategyMiddleware,
        renderLayoutMiddleware
      ),
},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
