import React from 'react';
import './styles/App.scss';
import Header from './components/Header';
import Config from './components/Config';
import LayoutEditor from './components/LayoutEditor';
import Drawing from './components/Drawing';
import ReactGA from 'react-ga4';

function App() {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('G-NZ0743VLYF');
  } else {
    console.warn('No analytics key provided, analytics will not be tracked');
  }
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });

  return (
    <div className="App">
      <Header />

      <main>
        <div id="description">
          <p>This page allows you to create fully featured <a href="https://github.com/dnnrly/layli">layli diagrams</a>. Use
            the editor below to define the diagram and hit "Generate SVG" to create the diagram on the right hand side. You
            can use the <a href="https://github.com/dnnrly/layli/blob/master/examples/README.md">examples</a> to get you
            started.</p>
        </div>
        <div id="drawing">
          <Drawing />
          <Config />
          <LayoutEditor />
        </div>
      </main>

      <footer id="mainFooter">
        <p>&copy; 2023 Pascal Dennerly. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;

