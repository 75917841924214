
export async function doDraw(diagramInput, showGrid) {
    return new Promise((resolve) => {
        // This timeout is necessary to give the browser's main thread the opportunity to
        // update the UI elements before getting blocked in the WASM call
        setTimeout(() => {
        const result = draw(diagramInput, showGrid);
        resolve(result);
        }, 100);
    });
}

export function convertToAbsoluteLayli() {
  const diagramData = document.getElementById('outputArea').innerHTML;

  var result = JSON.parse(svgToAbsolute(diagramData));
  if (result.error) {
  } else {
    inputTextArea.value = result.result;
    nodeLayout.value = 'absolute';
    pathStrategy.value = 'in-order';
  }
}
