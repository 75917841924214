import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDrawingError,
  setLayoutText,
  setConfigPathStrategy,
  setConfigNodeLayout,
  triggerLayoutRender
} from '../redux/actions';
import Diagram from '../components/Diagram';

import {
   setDrawingInProgress
 } from '../redux/actions';

const Drawing = () => {

  const dispatch = useDispatch();
  const layout = useSelector(state => state.layout);
  const drawing = useSelector(state => state.drawing);

  const handleGenerateClick = async () => {
    dispatch(triggerLayoutRender());
  }

  const handleToAbsoluteClick = async () => {
    dispatch(setDrawingInProgress(true));

    try {
      const result = JSON.parse(svgToAbsolute(drawing.svg));
      if (result.error) {
        dispatch(setDrawingError(result.error));
      } else {
        dispatch(setDrawingError(''));
        dispatch(setLayoutText(result.result));
        dispatch(setConfigNodeLayout('absolute'));
        dispatch(setConfigPathStrategy('in-order'));
      }
    }
    finally {
      dispatch(setDrawingInProgress(false));
    }
  }

  return <div className='flex flex-col'>

    <button
      id="generate" data-cy="generate"
      className={layout.error || drawing.inProgress ? "opacity-50 cursor-not-allowed" : ""}
      disabled={layout.error || drawing.inProgress ? true : false}
      onClick={handleGenerateClick}
    >Generate SVG</button>

    <input
      type="text"
      id="drawingError" data-cy="drawing-error"
      className="bg-gray-200 p-2 rounded-md"
      disabled
      value={drawing.error}
    />

    <Diagram />

    <button id="toAbsolute" data-cy="to-absolute"
      className={layout.error || drawing.inProgress ? "opacity-50 cursor-not-allowed" : ""}
      disabled={layout.error || drawing.inProgress ? true : false}
      onClick={handleToAbsoluteClick}
    >Convert to absolute layout</button>

  </div>

};

export default Drawing;