import {
    setLayoutText,
    setLayoutError,

    setDrawingError,
    setDrawingInProgress,
    setDrawingSVG,

    SET_CONFIG_NODE_LAYOUT,
    SET_CONFIG_PATH_STRATEGY,

    EVENT_RENDER_LAYOUT,
} from '../actions';

import {
    doDraw
} from '../../drawing';

export const resetLayoutMiddleware = storeAPI => next => action => {
    const result = next(action);

    if (action.type == SET_CONFIG_NODE_LAYOUT) {
        const state = storeAPI.getState();
        const layout = state.layout;

        console.log('layout changing to ', action.payload);
        let result = JSON.parse(layliChangeLayout(layout.text, action.payload));
        if (result.error) {
            storeAPI.dispatch(setLayoutError(result.error));
        } else {
            storeAPI.dispatch(setLayoutError(''));
            storeAPI.dispatch(setLayoutText(result.result));
        }
    }

    return result;
};

export const resetPathStrategyMiddleware = storeAPI => next => action => {
    const result = next(action);

    if (action.type == SET_CONFIG_PATH_STRATEGY) {
        const state = storeAPI.getState();
        const layout = state.layout;

        console.log('layout changing to ', action.payload);
        let result = JSON.parse(layliChangePathStrategy(layout.text, action.payload));
        if (result.error) {
            storeAPI.dispatch(setLayoutError(result.error));
        } else {
            storeAPI.dispatch(setLayoutError(''));
            storeAPI.dispatch(setLayoutText(result.result));
        }
    }

    return result;
};



export const renderLayoutMiddleware = storeAPI => next => async action => {
    const result = next(action);

    if (action.type == EVENT_RENDER_LAYOUT) {
        const state = storeAPI.getState();
        const layout = state.layout;
        const config = state.config;

        storeAPI.dispatch(setDrawingInProgress(true));

        try {
            const calcResult = JSON.parse(await doDraw(layout.text, config.showGrid == true));

            if (calcResult !== null && calcResult.hasOwnProperty('error')) {
                storeAPI.dispatch(setDrawingError(calcResult.error));
            } else {
                storeAPI.dispatch(setDrawingError(''));
                storeAPI.dispatch(setDrawingSVG(calcResult.result));
            }
        }
        finally {
            storeAPI.dispatch(setDrawingInProgress(false));
        }

        return result;
    }
}



export default {
    resetLayoutMiddleware,
    resetPathStrategyMiddleware,
    renderLayoutMiddleware,
};
