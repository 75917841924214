
export const SET_CONFIG_SHOW_GRID = 'SET_CONFIG_SHOW_GRID';
export const SET_CONFIG_NODE_LAYOUT = 'SET_CONFIG_NODE_LAYOUT';
export const SET_CONFIG_PATH_STRATEGY = 'SET_CONFIG_PATH_STRATEGY';

export const SET_LAYOUT_TEXT = 'SET_LAYOUT_TEXT';
export const SET_LAYOUT_ERROR = 'SET_LAYOUT_ERROR';

export const SET_DRAWING_IN_PROGRESS = 'SET_DRAWING_IN_PROGRESS';
export const SET_DRAWING_ERROR = 'SET_DRAWING_ERROR';
export const SET_DRAWING_SVG = 'SET_DRAWING_SVG';

export const EVENT_RENDER_LAYOUT = 'EVENT_RENDER_LAYOUT';

export const setConfigShowGrid = (showGrid) => ({
  type: SET_CONFIG_SHOW_GRID,
  payload: showGrid == true,
});

export const setConfigNodeLayout = (layout) => ({
  type: SET_CONFIG_NODE_LAYOUT,
  payload: layout,
});


export const setConfigPathStrategy = (pathStrategy) => ({
  type: SET_CONFIG_PATH_STRATEGY,
  payload: pathStrategy,
});

export const setLayoutText = (text) => ({
  type: SET_LAYOUT_TEXT,
  payload: text,
});

export const setLayoutError = (error) => ({
  type: SET_LAYOUT_ERROR,
  payload: error,
});

export const setDrawingInProgress = (inProgress) => ({
  type: SET_DRAWING_IN_PROGRESS,
  payload: inProgress,
});

export const setDrawingError = (error) => ({
  type: SET_DRAWING_ERROR,
  payload: error,
});

export const setDrawingSVG = (svg) => ({
  type: SET_DRAWING_SVG,
  payload: svg,
});

export const triggerLayoutRender = () => ({
  type: EVENT_RENDER_LAYOUT,
});
