import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigNodeLayout, setConfigPathStrategy, setConfigShowGrid } from '../redux/actions';

const Config = () => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    const handleShowGrid = (e) => {
      dispatch(setConfigShowGrid(e.target.checked));
    };

    const handleLayoutChange = (e) => {
      dispatch(setConfigNodeLayout(e.target.value));
    };

    const handlePathStrategyChange = (e) => {
      dispatch(setConfigPathStrategy(e.target.value));
    };

    return (<div className="flex flex-col">
        <button id="configReveal" onClick={toggleVisibility}>{isVisible ? 'Close' : 'Open'} config</button>
        <div id="configOptions" style={{ display: isVisible ? 'block' : 'none' }}>
            <div>
                <label htmlFor="showGrid">Show grid markers</label>
                <input type="checkbox" id="showGrid" data-cy="show-grid" value={config.showGrid} onChange={handleShowGrid} />
            </div>
            <div>
                <label htmlFor="nodeLayout">Layout style</label>
                <select id="nodeLayout" data-cy="node-layout" value={config.nodeLayout} onChange={handleLayoutChange}>
                    <option value="flow-square">Flow Square</option>
                    <option value="random-shortest-square">Random Shortest Square</option>
                    <option value="absolute">Absolute</option>
                    <option value="tarjan">Tarjan's Algorithm</option>
                </select>
            </div>pathStrategy
            <div>
                <label htmlFor="pathStrategy">Path strategy</label>
                <select id="pathStrategy" data-cy="path-strategy" value={config.pathStrategy} onChange={handlePathStrategyChange}>
                    <option value="in-order">Ordered</option>
                    <option value="random">Random</option>
                </select>
            </div>
        </div>
    </div>);
};

export default Config;
