import {
  SET_CONFIG_SHOW_GRID,
  SET_CONFIG_NODE_LAYOUT,
  SET_CONFIG_PATH_STRATEGY,
  SET_LAYOUT_ERROR,
  SET_LAYOUT_TEXT,
  SET_DRAWING_IN_PROGRESS,
  SET_DRAWING_ERROR,
  SET_DRAWING_SVG
} from '../actions';

const initialConfigState = {
  nodeLayout: 'flow-square',
  pathStrategy: 'in-order',
  showGrid: false,
};

const initialLayoutState = {
  text: '',
  error: '',
};

const initialDrawingState = {
  inProgress: false,
  svg: '',
  error: '',
};

export const configReducer = (state = initialConfigState, action) => {
  switch (action.type) {
    case SET_CONFIG_SHOW_GRID:
      return {
        ...state,
        showGrid: action.payload,
      };

    case SET_CONFIG_NODE_LAYOUT:
      return {
        ...state,
        nodeLayout: action.payload,
      };

    case SET_CONFIG_PATH_STRATEGY:
      return {
        ...state,
        pathStrategy: action.payload,
      };

    default:
      return state;
  }
};

export const layoutReducer = (state = initialLayoutState, action) => {
  switch (action.type) {
    case SET_LAYOUT_TEXT:
      return {
        ...state,
        text: action.payload,
      };
    case SET_LAYOUT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const drawingReducer = (state = initialDrawingState, action) => {
  switch (action.type) {
    case SET_DRAWING_IN_PROGRESS:
      return {
        ...state,
        inProgress: action.payload,
      };
    case SET_DRAWING_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_DRAWING_SVG:
      return {
        ...state,
        svg: action.payload,
      };
    default:
      return state;
  }
};

export default {
  config: configReducer,
  layout: layoutReducer,
};
